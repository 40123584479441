@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;
@tailwind utilities;

.svg-path-available {
    fill: #34D399;/* #049669; */
    cursor: pointer;
}

.svg-path-on_hold {
    fill: #FCE68A;
    cursor: pointer;
}

.svg-path-sold {
    fill: gray;
    cursor: pointer;
}

.svg-text-available {
    cursor: pointer;
}

.svg-text-on_hold {
    cursor: pointer;
}

.svg-text-sold {
    cursor: pointer;
}